
.dice-game-link {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover */
  overflow: hidden; /* Ensures pseudo-elements don't overflow outside the button */
}

.dice-game-link::before, .dice-game-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: left 0.5s;
}

.dice-game-link::before {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
}

.dice-game-link::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), transparent, rgba(255, 255, 255, 0.2));
}

.dice-game-link:hover::before, .dice-game-link:hover::after {
  left: 100%;
}

.dice-game-link:hover {
  color: #bf1b23; /* Blue text on hover */
}



#fixed-nav {
  align-items: flex-start; 
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #ac8000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 5px !important;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  z-index: 1000;
  margin: 0; /* Ensure no external margins are affecting the positioning */
  box-sizing: border-box; /* Include padding and border in the element's width and height */
}


html, body {
  width: 100%;
  max-width: 100vw; /* Ensures that the body doesn't exceed the width of the viewport */
  overflow-x: hidden;/* Prevents horizontal scrolling */
  margin: 0;
  padding: 0;
}




@media (max-width: 768px) {
  #fixed-nav {
    padding: 1px 0px; /* Increase padding if needed for smaller screens */
  }

  body {
    padding-top: 50px; /* Ensure this matches the mobile nav height */
  }
}



     .profile {
         width: 70px;
         height: 70px;
         border-radius: 50%;
         overflow: hidden;
         margin: 0 15px;
     }

     .profile img {
      width: 100%;
      height: auto;
      animation: nod 3s ease-in-out 10s 8; /* 15s duration, 10s delay, runs once */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .profile {
      width: 20px;  /* Reduced size for mobile */
      height: 50px;  /* Keep the same aspect ratio */
      margin: 0 10px; /* Reduced margin for smaller screen space */
  }
}
@keyframes moveAndBlinkText {
  0% {
      transform: translateX(100%); 
      opacity: 0;
  }
  10% {
      opacity: 0.1;  
  }
  90% {
      opacity: 1;  
  }
  100% {
      transform: translateX(-100%); 
      opacity: 0; 
  }
}
.marquee {
  font-size: 15px;
  margin-top: -20px;  
  display: block;
  white-space: nowrap;
  overflow: hidden;
  animation: moveAndBlinkText 7s linear infinite;
}

.marquee img {
  height: 1em;  /* Adjust size to match text height */
  vertical-align: middle;  /* Aligns image vertically with the text */
  margin: 0 0.5em;  /* Adds horizontal space around the image */
}


.nav-content {
  width: 100%;  /* Full width to align items */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons20-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;  /* Full width */
}

.icons20 {
  position: absolute;
  left: 160px; /* Adjust this value to position the icons from the left */
  bottom: 0; /* Positions at the bottom of the nav */
  display: flex;
  gap: 20px;
}

.icons20 a {
  color: #fff;  /* White color for links */
  text-decoration: none;
}

.icons20 a:hover  {
  transform: scale(2.0);  /* Scales up the image by 20% */
}

.icons20 a img {
  width: 24px; 
  height: 24px; 
  vertical-align: middle;
  transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;  /* Smooth transition for transform and border-radius */
  border-radius: 0%;  /* Start with no rounded corners */
}

.icons20 i {
  font-size: 28px;  /* Icon size */
}


@media (max-width: 768px) {
  .nav-content {
      flex-direction: column;
  }

  .icons20-wrapper {
      width: 100%;  /* Adjust width for mobile */
  }

  .icons20 {
      margin-top: 10px;  /* Space between content and icons on mobile */
      
  }
}


.navigation-links {
  justify-content: flex-start; /* Aligns items to the start */
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 520px; 
}

.link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px; /* Default spacing */
}
.navigation-links a:not(:last-child) {
  margin-right: 40px; /* Increased margin to spread out the links more */
}

@media (max-width: 768px) {
  .navigation-links {
      flex-direction: column;
  }

  .navigation-links a {
      margin: 5px 0; /* Adjust spacing for a vertical layout */
  }

  .navigation-links a:not(:last-child) {
    margin-right: 45px; /* Increased margin to spread out the links more */
  }

  .navigation-links {
    justify-content: flex-start; /* Aligns items to the start */
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    padding: 10px 5px; /* Adjusted for general padding */
  }

}

* {
  margin: 0;
  padding: 0;
}
.wrapper {
  position: fixed;    /* Fixed positioning */
  bottom: 0;          /* Anchor to the bottom */
  left: 0;            /* Align to the left */
  width: 100%;        /* Span the full width of the viewport */
  height: 100px;      /* Enough height to animate bubbles */
  overflow: hidden;   /* Hide anything going outside this container */
  pointer-events: none; /* Ensures clicks pass through to elements below */
  z-index: 9999;      /* High z-index to keep on top */
}

.wrapper.active {
  opacity: 1; /* Visible when active */
}

.wrapper div {
  height: 60px;
  width: 60px;
  border: 0.1px solid rgba(173, 216, 230, 0.3);
  border-radius: 50px;
  position: absolute;
  top: 20%;
  left: 20%;
  background: rgba(173, 216, 230, 0.3);
}

div .dot {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background-color: rgba(173, 216, 230, 0.3);
  position: absolute;
  top: 20%;
  right: 20%;
}
.wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}

.wrapper.active div {
  animation: animate 8s linear infinite;
}


@keyframes animate {
  0% {
    transform: scale(0.5) translateY(0) rotate(70deg);  /* Start smaller and rotated */
    opacity: 1;  /* Fully visible at start */
  }
  50% {
    transform: scale(1.2) translateY(-50px) rotate(215deg);  /* Grow, move up and rotate halfway */
    opacity: 0.6;  /* Start to fade out */
  }
  100% {
    transform: scale(0) translateY(-100px) rotate(360deg);  /* End smaller, up higher and full rotation */
    opacity: 0;  /* Fully transparent */
  }
}
