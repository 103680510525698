/* SimonCard.css */
.simon-card {
    display: flex;
    justify-content: center; /* Center the card horizontally */
    align-items: center; /* Center the card vertically */
    padding: 20px; /* Add padding around the card */
}

.card-image {
    width: 100%; /* Make the card container take full width of the parent */
    max-width: 300px; /* Max width for larger screens */
}

.card-image img {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s ease; /* Smooth transition for any transformation */
}

/* Media Queries for Manual Adjustments */
@media (max-width: 768px) {
    .card-image {
        max-width: 200px; /* Smaller max-width for mobile devices */
    }
}

@media (min-width: 769px) {
    .card-image {
        max-width: 300px; /* Larger max-width for desktops and tablets */
    }
}

/* Additional manual adjustment example */
@media (max-width: 480px) {
    .card-image {
        max-width: 150px; /* Even smaller for very small devices */
    }
}
