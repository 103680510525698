/* Tokenomics.css */
.tokenomics-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.tokenomics-input {
    margin-right: 0px;
    padding: 10px;
    width: auto;
    flex-grow: 1;
    border: 6px solid rgb(44 46 51);
    border-radius: 9px;
    background-color: black;  
    color: white; 
}

.tokenomics-button {
    padding: 10px 20px;
    border-radius: 6px;
    border: 6px solid rgb(44 46 51);;
    cursor: pointer;
    background-color: #555;
    color: black;  /* For better readability */
    
}
