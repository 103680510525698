html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App {
    width: 100%;
    max-width: 1200px;  
    font-family: Arial, sans-serif;
    background-color:  rgb(255 190 0);
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center contents vertically */
    align-items: center; /* Center contents horizontally */
    height: 100vh;
    padding-top: 39px; /* Adds some space from the top */
}

.overall-wrapper {
    background-color: rgb(254 183 92);  /* Example background color */
    padding: 50px;  /* Padding for the overall layout */
    min-height: 100vh;  /* Ensures it covers the full height of the viewport */
    width: 100%;  /* Ensures it covers the full width of the viewport */
    display: flex;
    flex-direction: inherit;
    align-items: center;  /* Center content horizontally */
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center contents horizontally */
    background-color: #1a1a1a; /* Slightly lighter or darker shade of black */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* 3D effect */
    border-radius: 10px;
    padding: 20px;
    width: calc(100% - 40px); /* Leave space at the edges */
    max-width: 1200px;
    box-sizing: border-box;
}

/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

footer {
    text-align: center;
    padding: 20px;
    background-color: black;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    border-top: 1px solid black;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
    margin-top: auto;
    width: 100%;
    flex-shrink: 0; /* Prevents the footer from shrinking */

    display: flex;
    flex-direction: column; /* Makes the images stack vertically */
    align-items: center;
}

.animated-image2 {
    width: 212px;  /* Adjust width as needed */
    margin-bottom: 100px;  /* Adds space between images */
}

footer p {
    margin: 10px 0;
    font-size: 18px;
    color: #d3d3d3;
}

footer p:first-child {
    
    font-weight: bold;
    margin-bottom: 1px;
    text-transform: uppercase;
    color: #ffffff; /* Gold color for the heading */
}

footer p:nth-child(2) {
    font-size: 3px;
    color: #d3d3d3;
}

* {
    margin: 0;
    padding: 0;
  }
  .wrapper {
    position: fixed;    /* Fixed positioning */
    bottom: 0;          /* Anchor to the bottom */
    left: 0;            /* Align to the left */
    width: 100%;        /* Span the full width of the viewport */
    height: 400px;      /* Enough height to animate bubbles */
    overflow: hidden;   /* Hide anything going outside this container */
    pointer-events: none; /* Ensures clicks pass through to elements below */
    z-index: 9999;      /* High z-index to keep on top */
  }
  
  .wrapper div {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
    left: 20%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.8) 0%, rgba(50, 50, 50, 0.6) 100%); /* Black watery color gradient */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Soft glow effect */

  }
  
  div .dot {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.8); /* Solid orange color for the dot */
    position: absolute;
    top: 20%;
    right: 20%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Soft glow effect for the dot */
  }
  
  .wrapper div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 2s linear infinite;
  }
  .wrapper div:nth-child(2) {
    top: 60%;
    left: 80%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(3) {
    top: 40%;
    left: 40%;
    animation: animate 1.5s linear infinite;
  }
  .wrapper div:nth-child(4) {
    top: 66%;
    left: 30%;
    animation: animate 3.5s linear infinite;
  }
  .wrapper div:nth-child(5) {
    top: 90%;
    left: 10%;
    animation: animate 4s linear infinite;
  }
  .wrapper div:nth-child(6) {
    top: 30%;
    left: 60%;
    animation: animate 2.5s linear infinite;
  }
  .wrapper div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 4s linear infinite;
  }
  .wrapper div:nth-child(8) {
    top: 75%;
    left: 60%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(9) {
    top: 50%;
    left: 50%;
    animation: animate 3s linear infinite;
  }
  .wrapper div:nth-child(10) {
    top: 45%;
    left: 20%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(11) {
    top: 10%;
    left: 90%;
    animation: animate 4.5s linear infinite;
  }
  .wrapper div:nth-child(12) {
    top: 20%;
    left: 70%;
    animation: animate 3.5s linear infinite;
  }
  .wrapper div:nth-child(13) {
    top: 20%;
    left: 20%;
    animation: animate 4s linear infinite;
  }
  .wrapper div:nth-child(14) {
    top: 60%;
    left: 5%;
    animation: animate 3s linear infinite;
  }
  .wrapper div:nth-child(15) {
    top: 90%;
    left: 80%;
    animation: animate 4.5s linear infinite;
  }
  
  .wrapper.active div {
    animation: animate 4s linear infinite;
  }


  .bubble-text {
    position: absolute;
    top: 50%;
    left: 50%;
    text-shadow: #1a1a1a;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7); /* Adjust color as needed */
    opacity: 1; /* Start hidden */
    font-weight: bold;
    text-shadow: 4px 4px 8px rgba(255, 255, 255, 0.7), 0 0 25px rgba(255, 255, 255, 0.5), 0 0 5px rgba(255, 255, 255, 0.5);
    animation: bubble-text-appear 4s linear infinite;
}
  
  @keyframes animate {
    0% {
      transform: scale(0.5) translateY(0) rotate(70deg);  /* Start smaller and rotated */
      opacity: 1;  /* Fully visible at start */
    }
    50% {
      transform: scale(1.2) translateY(-50px) rotate(215deg);  /* Grow, move up and rotate halfway */
      opacity: 0.6;  /* Start to fade out */
    }
    100% {
      transform: scale(0) translateY(-100px) rotate(360deg);  /* End smaller, up higher and full rotation */
      opacity: 0;  /* Fully transparent */
    }
  }
  



  #how-to-buy-section {
    overflow-x: hidden;  
    overflow-y: auto;    
    text-align: center;
    padding: 0px;
    color: white;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    background-color: white;
}

#how-to-buy-section h2 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #000000;
}

.step-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step {
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
}

.step h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);;
}

.step p {
    font-size: 18px;
    color: #d3d3d3;
    overflow: hidden; /* Hides any overflow */
}

.socials {
    margin-top: 40px;
    text-align: center;
}

.socials p {
    font-size: 24px;
    margin-bottom: 10px;
}

.icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.icons20 a img {
    width: 24px; 
    height: 24px; 
    vertical-align: middle;
    transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;  /* Smooth transition for transform and border-radius */
    border-radius: 0%;  /* Start with no rounded corners */
}

.icons a {
    color: black;
    font-size: 30px;
    transition: transform 0.3s, color 0.3s;
}

.icons a:hover {
    transform: scale(1.2);
    color: wheat;
}

/* Additional styles */
.profile-pic {
    width: 150px;  /* Adjust as necessary */
    height: 150px;  /* Adjust to maintain aspect ratio */
    border-radius: 50%;  /* Makes the image circular */
    overflow: hidden;  /* Hides anything outside the circle */
    margin: 0 15px;  /* Adjust spacing as needed */
    animation: fast-spin 0.06ms linear infinite;  /* Apply continuous fast spin animation */
}

@keyframes fast-spin {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.video-control {
    width: 100%;  /* Responsive width */
    max-width: 720px;  /* Limit maximum size */
    margin-bottom: 20px;  /* Space below video */
}

.fade-out, .zoom-in, .slide-in {
    animation: fadeIn 0.5s forwards;
}

.fade-in, .zoom-out, .slide-out {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(20px); }
}

/* Define similar keyframes for zoom-in/out and slide-in/out as needed */

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
    .socials {
        padding: 10px 0; /* Adds padding to ensure visibility and touchability */
    }
    .socials .icons {
        flex-direction: row; /* Keeps icons in a row, adjust if needed */
        justify-content: space-around; /* Distributes space around icons */
    }
    .icons a {
        font-size: 20px; /* Adjusts size for better visibility on small screens */
        padding: 5px; /* Increases padding for easier touch */
    }
}

/* Media Query for Screens Wider Than 768px */
@media (min-width: 768px) {
    .step {
        width: 50%;
        max-width: 600px;
        margin: 20px auto;
    }
}

/* Image Gallery Styles */
#image-gallery-section {
    padding: 20px;
}

.image-card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.0)
}


.wiki-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%; 
}

.wiki-link {
    display: inline-block;
    padding: 10px 20px;
    width: 100%; 
    max-width: 700px; 
    font-size: 43px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background: #ffd686;
    
    border-radius: 5px;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: color 0.5s ease;
}

/* Apply max-width only for screens narrower than a specific width */
@media (max-width: 1024px) {
    .wiki-link {
        max-width: 700px;
    }
}

.wiki-link::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    transition: left 0.5s;
}

.wiki-link:hover::before {
    left: 100%;
}

.wiki-link:hover {
    color: #0000ff;
}

.wiki-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), transparent, rgba(255, 255, 255, 0.2));
    transition: left 0.5s;
}

.wiki-link:hover::after {
    left: 100%;
}

.small-text {
    font-size: 9px; /* Adjust the size as needed */
    color: black; /* Optional: color of the text */
}
