footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ac8000;
  color: white;
  border-top: 1px solid black;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.footer-image-container {
  height: auto;  /* Maintain aspect ratio */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-image {
  width: 100%;  /* Adjusts width according to the container */
  height: auto;  /* Maintain aspect ratio */
}

.controls label {
  margin-right: 20px; /* Spacing between control labels */
}

@keyframes spin {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
