@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and borders are included in width/height calculations */
}

.container3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw; /* Viewport width */
  height: 10vh; /* Viewport height */
  font-family: 'Press Start 2P', cursive; /* Font style for the container */
  position: relative; /* Needed for absolute positioning inside */
}

.glitch {
  width: 300px; /* Width of the glitch box */
  height: 90px; /* Height of the glitch box */
  /*background: #4242e6; Dark blue background for glitch effect */
  background: gb(58, 60, 67);
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center in the viewport */
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  clip: rect(0, 900px, 0, 0);
  animation: glitch-animation 3s infinite linear alternate-reverse;
}

@keyframes glitch-animation {
  0%, 100% {
    clip: rect(42px, 900px, 44px, 0);
  }
  10% {
    clip: rect(48px, 900px, 29px, 0);
  }
  20% {
    clip: rect(63px, 900px, 27px, 0);
  }
  30% {
    clip: rect(86px, 900px, 73px, 0);
  }
  40% {
    clip: rect(26px, 900px, 60px, 0);
  }
  50% {
    clip: rect(57px, 900px, 98px, 0);
  }
  60% {
    clip: rect(82px, 900px, 31px, 0);
  }
  70% {
    clip: rect(28px, 900px, 99px, 0);
  }
  80% {
    clip: rect(23px, 900px, 85px, 0);
  }
  90% {
    clip: rect(45px, 900px, 47px, 0);
  }
}
