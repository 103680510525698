#how-to-buy {
    overflow-x: hidden;  
    overflow-y: auto;    
    text-align: center;
    padding: 0px;
    color: white;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    background-color: #a56700;
}

#how-to-buy h2 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #ffffff;
}

.step-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step {
    background-color: rgb(255 190 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
}

.step h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
}

.step p {
    font-size: 18px;
    color: #000000;
    overflow: hidden; /* Hides any overflow */

}

.socials {
    margin-top: 40px;
    text-align: center;
}

.socials p {
    font-size: 24px;
    margin-bottom: 10px;
}

.icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.icons20 a img {
    width: 24px; 
    height: 24px; 
    vertical-align: middle;
    transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;  /* Smooth transition for transform and border-radius */
    border-radius: 0%;  /* Start with no rounded corners */
}

.icons a {
    color: black;
    font-size: 30px;
    transition: transform 0.3s, color 0.3s;
}

.icons a:hover {
    transform: scale(1.2);
    color: wheat;
}



/* Additional styles */
.profile-pic {
    width: 150px;  /* Adjust as necessary */
    height: 150px;  /* Adjust to maintain aspect ratio */
    border-radius: 50%;  /* Makes the image circular */
    overflow: hidden;  /* Hides anything outside the circle */
    margin: 0 15px;  /* Adjust spacing as needed */
    animation: fast-spin 0.06ms linear infinite;  /* Apply continuous fast spin animation */
}


@keyframes fast-spin {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}



.video-control {
    width: 100%;  /* Responsive width */
    max-width: 720px;  /* Limit maximum size */
    margin-bottom: 20px;  /* Space below video */
}

  .fade-out, .zoom-in, .slide-in {
    animation: fadeIn 0.5s forwards;
  }
  
  .fade-in, .zoom-out, .slide-out {
    animation: fadeOut 0.5s forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(20px); }
  }
  
  /* Define similar keyframes for zoom-in/out and slide-in/out as needed */
  
  /* Media Query for Mobile Devices */
@media (max-width: 767px) {
    .socials {
        padding: 10px 0; /* Adds padding to ensure visibility and touchability */
    }
    .socials .icons {
        flex-direction: row; /* Keeps icons in a row, adjust if needed */
        justify-content: space-around; /* Distributes space around icons */
    }
    .icons a {
        font-size: 20px; /* Adjusts size for better visibility on small screens */
        padding: 5px; /* Increases padding for easier touch */
    }
}

/* Media Query for Screens Wider Than 768px */
@media (min-width: 768px) {
    .step {
        width: 50%;
        max-width: 600px;
        margin: 20px auto;
    }
}


