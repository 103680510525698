.image-card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 10px auto; /* Centering the image */
    border-radius: 8px;
    overflow: hidden;
    width: 40%; /* Maintain size but adjust for responsiveness */
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 640px; /* Adjusted for a better fit */
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    padding: 10px;
}

.thumbnails {
    display: flex;
    overflow-x: auto; /* Ensures scrollability */
    white-space: nowrap;
    padding: 10px 0;
    justify-content: flex-start; /* Aligns thumbnails to the start */
}

.thumbnail, .active-thumbnail {
    cursor: pointer;
    width: 25px; /* Smaller size for ease of scrolling */
    height: auto;
    margin-right: 5px; /* Space between thumbnails */
    transition: transform 0.3s ease;
    border-radius: 4px; /* Aesthetic choice for rounded corners */
}

.active-thumbnail {
    transform: scale(1.1);
    border: 2px solid #fff; /* Highlights the active thumbnail */
}

.thumbnail:hover {
    transform: scale(1.05);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .carousel {
        height: auto; /* Adjust height for smaller screens */
    }

    .thumbnails {
        justify-content: center; /* Center thumbnails on smaller screens */
    }
}