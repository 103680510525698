.wiki-link {
    font-size: 16px; /* Adjust the font size to your preference */
    color: #007BFF; /* Use a standard link blue for visibility and familiarity */
    text-decoration: none; /* Remove underline */
    padding: 8px; /* Padding for better clickability */
    transition: color 0.3s; /* Smooth transition for color change */
}

.wiki-link:hover {
    color: #0056b3; /* Darker blue on hover for interaction feedback */
    text-decoration: underline; /* Add underline on hover to indicate it is clickable */
}
